<template>
  <div id="wrapper" class="app">
    <Nav />
    <div style="padding-bottom: 100px" class="mt-5 container">
      <br />
      <router-view />
    </div>
    <div v-if="store.state.isLoading">
      <Spinner />
    </div>
    <div id="erpa-footer">
      <Footer />
    </div>
    <img
      style="position: fixed; top: 0; height: 100%; width: 100%; z-index: -999"
      src="../public/img/icons/Background.jpg" />
  </div>
</template>

<script setup>
import Nav from "./components/nav.vue";
import Footer from "./components/footer.vue";
import Spinner from "./components/Spinner";
import { useStore } from "vuex";
import { onMounted, ref } from "vue";

const store = useStore();
let erpaFooter = ref(undefined);
window.addEventListener("scroll", toggleFooter);
//on resize event listener
window.addEventListener("resize", toggleFooter);

onMounted(() => {
  erpaFooter.value = document.getElementById("erpa-footer");
  erpaFooter.value.style.display = "none";
  toggleFooter();
});

function isScrolledToBottom() {
  if (window.innerHeight + 5 + Math.round(window.scrollY) >= document.body.offsetHeight) {
    return true;
  }
  return false;
}

function toggleFooter() {
  if (!erpaFooter.value) {
    return;
  }

  if (window.innerWidth < 768) {
    if (erpaFooter.value && isScrolledToBottom()) {
      erpaFooter.value.style.display = "block";
    } else {
      if (erpaFooter.value) {
        erpaFooter.value.style.display = "none";
      }
    }
  } else {
    erpaFooter.value.style.display = "block";
  }
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.addEventListener('message', (event) => {
    alert('serviceWorker in navigator')
    if (event.data.type === 'SKIP_WAITING') {
      // clear browser cache and perform a full page refresh to load the new version
      if ('caches' in window) {
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name);
          }
        });
      }
      // Perform a full page refresh to load the new version
      alert('new version downloaded')
      console.log('new version downloaded')
      window.location.reload();
    }
  });
}

</script>

<style>
.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.container {
  background-color: white;
  opacity: 1;
  border-radius: 10px;
}

.centered-content {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  align-content: center !important;
  justify-items: center !important;
  justify-content: center !important;
  vertical-align: middle !important;
}

.left-content {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  align-content: flex-start !important;
  justify-items: left !important;
  justify-content: left !important;
  vertical-align: middle !important;
}

.left-content-horizontal {
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: center;
}

.right-content {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-end !important;
  align-content: flex-end !important;
  justify-items: right !important;
  justify-content: right !important;
  vertical-align: middle !important;
}

.material-icons {
  cursor: pointer;
}

img {
  max-width: 100%;
}
</style>
