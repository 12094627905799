/* eslint-disable no-console */

import { register } from 'register-service-worker'
import { createApp } from 'vue'
import Toast from 'vue-toastification'

// Create a simple event bus for handling the update event
const updateEvent = new CustomEvent('swUpdated')

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered(registration) {
      console.log('Service worker has been registered.')
      // Check for updates at startup
      registration.update()
      // Check for updates every 5 minutes
      setInterval(() => {
        registration.update()
      }, 1000 * 60 * 5)
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(registration) {
      console.log('New content is available; please refresh.')
      document.dispatchEvent(updateEvent)

      // Show a toast notification
      const toast = createApp({
        render: () => null
      }).use(Toast).mount(document.createElement('div'))

      toast.$toast.info(
        'A new version is available! Click here to update.',
        {
          timeout: -1,
          closeOnClick: false,
          onClick: () => {
            window.location.reload()
          }
        }
      )
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
}